import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const CardBlog = ({ data }) => {
  const { category, cover, createdAt, id, name, slug, title } = data;
  return (
    <div key={id} className='col-12 col-md-6 col-lg-4 mb-3'>
      <div className='blog-card strip-white'>
        <Link to={`/blog/${slug}`}>
          <div className='blog-cover'>
            {cover && (
              <GatsbyImage image={cover.gatsbyImageData} alt={cover.title} />
            )}
          </div>
          <div className='blog-content'>
            <div className='blog-title'>{title}</div>
            <p>
              <span>{category?.name}</span>
              &nbsp;&nbsp;&middot;&nbsp;&nbsp;
              {createdAt}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CardBlog;

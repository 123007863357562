import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import CardBlog from '../components/CardBlog';
import Layout from '../components/Layout';

const Blog = (props) => {
  const blogs = props.data.blogs.edges;
  const perPage = 12;
  const [list, setList] = React.useState([...blogs.slice(0, perPage)]);
  const [loadMore, setLoadMore] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(blogs.length > perPage);

  const handleLoadMore = () => {
    setLoadMore(true);
  };

  React.useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < blogs.length;
      const nextResults = isMore
        ? blogs.slice(currentLength, currentLength + perPage)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  React.useEffect(() => {
    const isMore = list.length < blogs.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <Layout bodyClass='page-blogs'>
      <div className='strip strip-blog strip-blog-page'>
        <div className='container'>
          <span className='section-title mb-3'>Blogs</span>
          <div className='row'>
            {list.map(({ node }, index) => (
              <CardBlog key={node.id} data={node} />
            ))}
          </div>
          {hasMore && (
            <div className='row'>
              <div className='col-auto'>
                <button
                  className='button button-secondary btn-as-link'
                  onClick={handleLoadMore}
                >
                  Load More
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    blogs: allContentfulBlog(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          category {
            name
          }
          createdAt(formatString: "MMMM DD, YYYY")
          cover {
            title
            gatsbyImageData(
              width: 360
              height: 210
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          slug
          title
          post {
            raw
          }
        }
      }
    }
  }
`;

export const Head = () => <SEO title='Blogs' />;

export default Blog;
